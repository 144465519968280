import { Disclosure as HUIDisclosure } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";
import { CaretDown } from "phosphor-react";
import { ReactElement } from "react";
import useWindowDimensions from "../../lib/useWindowDimensions";

type DisclosureTints = "default";
type DisclosureProps = {
  children: any;
  icon: ReactElement;
  label: string;
  tint?: DisclosureTints;
  className?: string;
  color?: string;
};

function Disclosure({
  label,
  icon,
  children,
  className,
  color,
}: DisclosureProps) {
  const { width } = useWindowDimensions();
  const isDesktop = width >= 1024;

  return (
    <HUIDisclosure
      as={motion.div}
      className={`${
        color ?? ""
      } flex-auto rounded overflow-hidden flex flex-col justify-stretch `}
      transition={{
        duration: 0,
      }}
      layout
    >
      {({ open }) => (
        <>
          <HUIDisclosure.Button
            as={motion.div}
            className={`${isDesktop || open ? `py-3.5` : `py-2`} ${
              isDesktop ? `` : `cursor-pointer`
            } flex items-center justify-between px-4 prose-lg font-bold text-left text-gray-900 cursor-pointer 2xl:cursor-auto 2xl:prose-xl`}
            transition={{
              duration: 0,
            }}
            layout
          >
            <motion.span className={` flex items-center space-x-3`} layout>
              <span className={`relative w-10 h-10 shadow rounded`}>
                {icon}
              </span>
              <span>{label}</span>
            </motion.span>
            <motion.span className={"lg:invisible"} layout>
              <CaretDown
                weight={`regular`}
                className={`${open && "transform -rotate-180"}`}
              />
            </motion.span>
          </HUIDisclosure.Button>

          <AnimatePresence>
            <HUIDisclosure.Panel
              as={motion.div}
              key={"mobile"}
              className={`${className} lg:hidden`}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
              transition={{
                duration: 0,
              }}
              layout
            >
              {children}
            </HUIDisclosure.Panel>

            <HUIDisclosure.Panel
              static
              key={"desktop"}
              as={motion.div}
              className={`${className} hidden lg:flex`}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
              transition={{
                duration: 0,
              }}
              layout
            >
              {children}
            </HUIDisclosure.Panel>
          </AnimatePresence>
        </>
      )}
    </HUIDisclosure>
  );
}

export default Disclosure;
